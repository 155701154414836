<template>
  <div v-if="loadingModules" class="d-flex justify-content-center align-items-center">
    <div class="spinner-border" role="status"></div>
  </div>
  <div v-else>
    <main v-if="!loadingModules && currentStaff.role === 'user'">
      <b-card class="text-center py-2" no-body>
        <div class="input-group input-group-flush d-flex flex-row-reverse">
          <input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search by permissions" @keyup.enter.prevent="handlePermissionsSearch(true)" />
          <span class="input-group-text border-0">
            <i class="fe fe-search pointer" @click.prevent="handlePermissionsSearch(true)"></i>
          </span>
        </div>
      </b-card>

      <div class="container-fluid" v-for="(permissionModule, index) in accessModules" :key="index">
        <div class="d-flex justify-content-between items-center">
          <p style="color: #737876" class="font-weight-bold">
            {{ permissionModule.name }}
          </p>
          <div v-if="handleCheck(permissionModule.name)" class="spinner-border text-primary spinner-border-sm pl-2" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <b-card v-if="permissionModule.permissions.length">
          <b-row cols-sm="1" cols-md="2" cols-lg="3">
            <b-card-text class="grid spinnerParent" style="color: #09090f" v-for="(eachPermission, index) in permissionModule.permissions" :key="index">
              <!-- checkbox here -->
              <b-col class='flex gap-3 items-center'>
                <input @change="
                  selectedPermission(
                    $event,
                    eachPermission,
                    permissionModule.name
                  )
                  " class="m-0 p-0" :id="eachPermission.code" type="checkbox" :checked="checkIfPermissionExistForModule(eachPermission.id)" />
                <label :for="eachPermission.code" class='cursor-pointer m-0 p-0'>{{ eachPermission.description }} </label>

                <div v-if="handleLoader(eachPermission.id)" class="spinner-border text-primary spinner-border-sm pl-2" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </b-col>
            </b-card-text>
          </b-row>
        </b-card>
        <b-card v-else class="text-muted">
          {{ permissionModule.name }} permissions not available.
        </b-card>
      </div>

      <div class="card-footer d-flex justify-content-end align-items-end" v-if="totalRecords">
        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
      </div>

      <div class="container-fluid pt-5">
        <b-card class="text-center" style="
          background-color: #fcedee;
          border-style: dotted;
          border-color: red;
        ">
          <b-row>
            <b-col cols="9">
              <div class="d-flex flex-column justify-content-start align-items-start">
                <h3 style="color: #000005" class="font-weight-bold pb-0 mb-0">
                  Remove all modules/permissions
                </h3>
                <p style="color: #575a65" class="pt-1 mt-1">
                  Are you sure you want to remove all modules and permissions from
                  this user?
                </p>
              </div>
            </b-col>

            <b-col>
              <b-button @click="handlePermissionsDelete" variant="danger" size="lg">Clear all permissions</b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </main>
    <main v-else>
      <p>All Super admins and Admins have all permissions by default</p>
    </main>
  </div>
</template>

<script setup>
import { getAdminType } from '@/composables/core/permissions/admin'
</script >

<script>
import Swal from 'sweetalert2'
export default {
  name: 'StaffPermissions',
  created()
  {
    this.init()
  },
  props: {
    staffId: {
      required: true,
      type: [Number, String]
    }
  },
  data()
  {
    return {
      selected: '',
      search: '',
      loadingModules: false,
      permissionLoading: true,
      accessModules: [],
      confirmedPermissions: [],
      currentStaff: {},
      staffPermissions: [],
      permission: null,
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      singleLoader: false,
      activeSpinners: [],
      currentLoader: []
    }
  },
  methods: {
    handleCheck(moduleName)
    {
      return this.activeSpinners.find((e) => e.moduleName === moduleName)
    },
    handleLoader(id)
    {
      return !!this.currentLoader.includes(id)
    },
    addCurrentLoader(id)
    {
      this.currentLoader.push(id)
    },
    removeCurrentLoader(id)
    {
      const index = this.currentLoader.indexOf(id)
      if (index > -1) {
        this.currentLoader.splice(index, 1)
      }
    },
    addSpinners(moduleName, permissionId)
    {
      const index = this.activeSpinners.findIndex(
        (e) => e.permissionId === permissionId && e.moduleName === moduleName
      )

      if (index === -1) {
        this.activeSpinners.push({
          moduleName,
          permissionId
        })
      }
    },
    removeSpinners(moduleName, permissionId)
    {
      const index = this.activeSpinners.findIndex(
        (e) => e.permissionId === permissionId && e.moduleName === moduleName
      )
      if (index === -1) return false
      this.activeSpinners.splice(index, this.activeSpinners.length)
    },
    init()
    {
      this.getAccessControlData()
      this.getStaffPermissions()
    },
    selectedPermission($event, permission, moduleName)
    {
      // first check if the permission exists;
      const found = this.staffPermissions.find(
        (perm) => perm.id === permission.id
      )
      const staff_permission_id = found?.staff_permission_id
      if ($event.target.checked) {
        this.$nextTick(() =>
        {
          // this.addSpinners(moduleName, permission.id)
          this.addCurrentLoader(permission.id)
          this.axios
            .post('/v1/super-admin/staff-module-permissions', {
              permission_id: permission.id,
              staff_id: this.staffId
            })
            .then(() =>
            {
              this.$toastr.s(
                'Permission status was updated successfully',
                'Success'
              )
            })
            .catch(() =>
            {
              this.$toastr.e(
                'Something went wrong while updating permission status',
                'Error'
              )
            })
            .finally(() =>
            {
              // this.init()
              this.getStaffPermissions()
              // this.removeSpinners(moduleName, permission.id)
              this.removeCurrentLoader(permission.id)
            })
        })
      } else {
        if (!found) {
          this.$toastr.e('Invalid request', 'Error')
          return
        }
        this.$nextTick(() =>
        {
          this.permissionLoading = true
          this.addCurrentLoader(permission.id)
          this.axios
            .delete(
              `/v1/super-admin/staff-module-permissions/${staff_permission_id}`
            )
            .then(() =>
            {
              this.permissionLoading = false
              this.$toastr.s(
                'Permission status was updated successfully',
                'Success'
              )
            })
            .catch(() =>
            {
              this.permissionLoading = false
              this.$toastr.e(
                'Something went wrong while updating permission status',
                'Error'
              )
            })
            .finally(() =>
            {
              // this.init()
              this.getStaffPermissions()
              // this.removeSpinners(moduleName, permission.id)
              this.removeCurrentLoader(permission.id)
            })
        })
      }
    },
    getAccessControlData()
    {
      this.loadingModules = true
      this.axios
        .get(
          `/v1/super-admin/access-modules?limit=${this.perPage}&page=${this.currentPage}`
        )
        .then((res) =>
        {
          this.accessModules = res.data.data.sort((a, b) =>
          {
            const nameA = a.name.toLowerCase()
            const nameB = b.name.toLowerCase()

            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
          this.totalRecords = res.data.metadata.total
        })
        .catch(() =>
        {
          this.$toastr.e('something went wwrong', 'Error')
        })
        .finally(() =>
        {
          this.loadingModules = false
        })
    },
    getStaffPermissions()
    {
      this.axios
        .get(`/v1/super-admin/staff-module-permissions/staff/${this.staffId}`)
        .then((res) =>
        {
          this.currentStaff = res.data.staff
          this.staffPermissions = res.data.permissions
        })
        .catch(() =>
        {
          this.$toastr.e('something went wrong', 'Error')
        })
    },
    checkIfPermissionExistForModule(modulePermissionId)
    {
      return !!this.staffPermissions.find(
        (permission) => permission.id === modulePermissionId
      )
    },
    async handlePermissionsDelete()
    {
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to delete all permissions?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () =>
        {
          return this.axios
            .delete(
              `/v1/super-admin/staff-module-permissions/staff/${this.staffId}`
            )
            .then(() =>
            {
              this.init()
            })
            .catch((error) =>
            {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) =>
      {
        if (result.isConfirmed) {
          this.$toastr.s(
            'Modules/Permissions have been successfully deleted',
            'Success'
          )
        }
      })
    }
  },
  computed: {
    currentUser()
    {
      return this.$store.getters.currentUser
    },

    filteredModules()
    {
      const search = this.search.toLowerCase()
      let permissions = this.accessModules.filter((eachModule) =>
      {
        return eachModule.name.toLowerCase().includes(search)
      })

      if (!search.length) {
        permissions = this.accessModules
      }
      return permissions
    }
  },
  watch: {
    currentPage()
    {
      this.getAccessControlData()
    }
  }
}
</script>

<style scoped>
.spinnerParent {
  position: relative;
}

.loadingSpinner {
  position: absolute;
  top: 0px;
  right: 5em;
}
</style>
